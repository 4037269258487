import React, { useEffect, useState } from 'react';
import { ethers, Contract } from 'ethers';
import { BrowserProvider, formatEther, parseEther, getAddress } from 'ethers';
import { wl1Contract } from './wl1';
import "./index.css";
import "./App.css";

declare global {
  interface Window {
    ethereum: any;
  }
}

function App() {
  const [provider, setProvider] = useState<BrowserProvider | null>(null);
  const [network, setNetwork] = useState('');
  const [supply, setSupply] = useState('');
  const [txHash, setTransactionHash] = useState('');
  const [wrapValue, setWrapValue] = useState('');
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [unwrapValue, setUnwrapValue] = useState('');
  const [contract, setContract] = useState<Contract | null>(null);
  const [wrap, setWrap] = useState<Function>();
  const [unwrap, setUnwrap] = useState<Function>();
  const [account, setAccount] = useState('');
  const [connected, setConnected] = useState(false);
  const [balance, setBalance] = useState('');
  const [accountListener, setAccountListener] = useState<(() => Promise<void>) | null>(null);
  const [blockNumber, setBlockNumber] = useState<number | null>(null);


  useEffect(() => {
    if (!provider) return;
    const tempContract = new Contract(wl1Contract.address, wl1Contract.abi, provider);
    setContract(tempContract);

    provider.getSigner().then(signer => {
      signer.getAddress().then(address => {
        setAccount(address);
        setConnected(true);
        if (contract) {
          contract.totalSupply().then(result => {
            setSupply(formatEther(result));
          });
        }
      });
    });

    if (window.ethereum) {
      const onBlock = (newBlockNumber: number) => {
        setBlockNumber(newBlockNumber);
      };

      provider.on('block', onBlock);

      // Cleanup the event listener when the component is unmounted
      return () => {
        provider.off('block', onBlock);
      };

    }
  }, [provider]);

  useEffect(() => {
    const initializeProvider = async () => {
      if (window.ethereum) {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.BrowserProvider(window.ethereum);
        setProvider(provider);
      }
    };

    initializeProvider();

    window.ethereum.on('accountsChanged', (accounts: string[]) => {
      setConnected(accounts && accounts.length > 0);
      const provider = new ethers.BrowserProvider(window.ethereum);
      setProvider(provider);
    });



  }, []);

  // Function to connect/disconnect the wallet
  async function connectWallet() {
    if (!connected) {
      // Connect the wallet using ethers.js
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const _walletAddress = await signer.getAddress();

      setProvider(provider);
      setAccount(_walletAddress);
      setConnected(true);
    } else {
      // Disconnect the wallet
      //     window.ethereum.selectedAddress = null;
      setConnected(false);
      setAccount("");
      setBalance("");
    }
  }

  useEffect(() => {
    // ...

    const getNetwork = async () => {
      if (provider) {
        const network = await provider.getNetwork();
        setNetwork(network.chainId.toString());
      }
    };

    getNetwork();

    const wrap = async () => {
      if (contract && provider ) {
        const signer = await provider.getSigner();
        const transaction = await signer.sendTransaction({
          to: wl1Contract.address,
          value: parseEther(wrapValue),
        });
        setTransactionHash(transaction.hash);
      }
    }
    setWrap(() => wrap); 

    const unwrap = async () => {
      if (contract && provider && unwrapValue) {
        const signer = await provider.getSigner();
        const contractWithSigner = contract.connect(signer);
        const transaction = await (contractWithSigner as any ).withdraw(parseEther(unwrapValue));
        setTransactionHash(transaction.hash);
      }
    }

    setUnwrap(() => unwrap);

    if (connected && contract) {
      contract.totalSupply().then(result => {
        setSupply(formatEther(result));
      });
    }

    if (contract && account) {
      contract.balanceOf(account).then(result => {
        setBalance(formatEther(result));
      });
    }
  }, [provider, contract, wrapValue, unwrapValue, blockNumber]);


  return (
    <div>
      <div>
        <div className="container">
          <h1>L1.Finance - Basic Tools for the L1 Faithful</h1>
          <p>Look here for utilities to engage with the L1 network. Unauthorized, community-run, use at your own risk.</p>
        </div>
        <div className="container">
          <h1>Wrap L1 to make WL1</h1>
          <div>
            <p>Wrapped L1 can be used in a variety of contracts; anything that might want programmatic access to your L1 tokens could prefer L1. WL1 is supported by <a href="https://personacollective.ai">Persona Collective</a> at launch for bidding in the secondary market!</p>
            <p>To turn your Wrapped L1 into native L1, Click the "Withdraw" button below.</p>
            <p>The WL1 Contract charges no fees, but the Lamina1 network does charge gas for all transactions.</p>
            <p>Contract: <a href="https://explorer.lamina1.com/token/0xeE38CcfA3726a2aA8B168BDCc05D60A2397f94C8">0xeE38CcfA3726a2aA8B168BDCc05D60A2397f94C8</a>.</p>
            <p>  
              { (account && connected ? "Connected Account: " + account : "") }

              <button className="btn-sm" onClick={connectWallet}>
                {connected ? "Disconnect Wallet" : "Connect Wallet"}
              </button>
            </p>
            <div className="scontainer">
              <span><h2> { ( supply ?  "Total Issued WL1: " +  supply   : "") }</h2></span>
              <span><h2>{ ( balance ? "You Have: " + balance + " WL1"  : "") }</h2></span>
            </div>
          </div>
          { (account ? <div className="scontainer">
            <span>
              <input 
              type="text" 
              value={wrapValue} 
              onChange={(e) => setWrapValue(e.target.value)} 
              placeholder="Enter amount to wrap" 
            /> 
                <br />
                <button className="btn" onClick={() => wrap && wrap()}>
                  Wrap
                </button>
              </span>
              <span>
                <input 
                type="text" 
                value={unwrapValue} 
                onChange={(e) => setUnwrapValue(e.target.value)} 
                placeholder="Enter amount to unwrap" 
              /> 
                  <br />
                  <button className="btn" onClick={() => unwrap && unwrap()}>
                    Unwrap
                  </button>
                </span>
                </div> : "") }
                <div className="footer">Version {process.env.REACT_APP_VERSION}</div>
              </div>
            </div>
            <div className="container">
              <h2>ERC 6551 Info</h2>
              <div>Registry Address: <a href="https://explorer.lamina1.com/address/0x000000006551c19487814612e58FEe6813775758">0x000000006551c19487814612e58FEe6813775758</a></div>
              <div>Simple Reference Account Address: <a href="https://explorer.lamina1.com/address/0x6670F43a1524f62433fa57D61bC373A411DfbA96">0x6670F43a1524f62433fa57D61bC373A411DfbA96</a></div>
            </div>

          </div>
  );
}

export default App;

